import { queryLoyaltyCheckoutDiscounts } from '@wix/ambassador-loyalty-checkoutexchange-v1-loyalty-checkout-discount/http';
import { LoyaltyCheckoutDiscount } from '@wix/ambassador-loyalty-checkoutexchange-v1-loyalty-checkout-discount/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyCheckoutDiscounts(
  flowAPI: PlatformControllerFlowAPI,
  checkoutId: string,
): Promise<LoyaltyCheckoutDiscount[]> {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  const response = await withErrorHandler(
    () =>
      httpClient.request(
        queryLoyaltyCheckoutDiscounts({
          query: {
            filter: { checkoutId },
          },
        }),
      ),
    { errorCodesMap: {} },
  );

  return response.data.loyaltyCheckoutDiscounts!;
}
