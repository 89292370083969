import { listEarningRules } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/http';
import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyEarningRules(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyEarningRule[]> {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  const response = await withErrorHandler(() => httpClient.request(listEarningRules({})), {
    errorCodesMap: {},
  });

  return response.data.earningRules!;
}
