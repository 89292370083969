import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyRewards(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyReward[]> {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  const response = await withErrorHandler(
    () =>
      httpClient.request(
        listRewards({
          redemptionContext: true,
        }),
      ),
    { errorCodesMap: {} },
  );

  return response.data.rewards!;
}
